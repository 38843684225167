import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import FilterText from "../FilterText";
import FilterContainer from "../FilterContainer";
import { useBackdropContext } from "../../../../contexts/backdrop/BackdropProvider";
import { asyncSearchList } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { ID_APP } from "../../../../utils/constants";
import FilterList from "../FilterList";
import FilterRadios from "../FilterRadios";
import { IFilterProps } from "../../../../types/filter.type";

type ItemType = {
  label: string;
  value: string;
  subs?: ItemType[];
};

function FilterHangHoa({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    vat_tu: "",
    slug: "",
    nhom_vat_tu: [],
    trang_thai: "true",
  };
  const token = useSelector((state: any) => state.auth.token);
  const { setOpenBackdrop } = useBackdropContext();
  const [groups, setGroups] = useState<ItemType[]>([]);
  const [filter, setFilter] = useState<{ [key: string]: any }>(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  const getNvt = async () => {
    try {
      setOpenBackdrop?.(true);
      const resp = await asyncSearchList({
        apiCode: "dmnvt",
        token,
        idApp: ID_APP,
        condition: { page: 1, limit: 9999999 },
      });
      if (resp && resp?.status === 200) {
        const data = resp?.data || [];
        const parent: ItemType[] = [];
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          if (item?.exfields?.la_nhom_me) {
            const subs = data
              .filter((nvt: any) => nvt.nh_me === item._id)
              ?.map((item: any) => ({ label: item.ten_nvt, value: item._id }));
            parent.push({
              label: item.ten_nvt,
              value: item._id,
              subs,
            });
          }
        }
        setGroups(parent);
      }
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.vat_tu) {
      condition.$or = [
        {
          ma_vt: {
            $regex: filter.vat_tu.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_vt: {
            $regex: filter.vat_tu.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_vt_en: {
            $regex: filter.vat_tu.split(" ").join(".*"),
            $options: "i",
          },
        },
      ];
    }
    if ((filter.nhom_vat_tu || []).length > 0) {
      condition.ma_nvt = { $in: filter.nhom_vat_tu };
    }
    switch (filter.trang_thai) {
      case "true":
        condition.status = true;
        break;
      case "false":
        condition.status = false;
        break;
      default: // all
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  useEffect(() => {
    getNvt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack gap={1}>
        <FilterText
          title="Mã, Tên sản phẩm"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.vat_tu}
          onSearch={(value) => setFilter({ vat_tu: value })}
        />
        <FilterList
          title="Nhóm hàng"
          items={groups}
          defaultValues={filter.nhom_vat_tu}
          onChange={(value) => setFilter({ ...filter, nhom_vat_tu: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: "Tất cả", value: "all" },
            { label: "Đang kinh doanh", value: "true" },
            { label: "Ngừng kinh doanh", value: "false" },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterHangHoa;
