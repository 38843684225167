import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FaRegFileExcel } from "react-icons/fa";
import ModalBase from "../modal/ModalBase";
import TableApp from "../tableapp/TableApp";
import { TbFileExport } from "react-icons/tb";
import { TableColumn } from "react-data-table-component";
import { generateSearchKeywords } from "../../../utils/helpers";
import axios from "axios";
import { API_URL, ID_APP } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { asyncGetList } from "../../../utils/httpRequests";
import { isArray } from "lodash";

interface IExportToExcelButton {
  apiCode: string;
  query?: { [key: string]: any };
  filenameExport?: string;
  setLoad?: Dispatch<SetStateAction<number>>;
  renderDisplay?: (params: { openModal?: () => void }) => React.ReactNode;
}

function ButtonExportToExcel({
  apiCode,
  query = {},
  filenameExport,
  setLoad,
  renderDisplay,
}: IExportToExcelButton) {
  const { showAlert } = useAlertContext();
  const { token } = useSelector((state: any) => state.auth);
  const [openExportExcel, setOpenExportExcel] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [fileExcels, setFileExcels] = useState<any[]>([]);

  const fileName = filenameExport || `${Date.now().toString()}_${apiCode}.xlsx`;

  const handleOpenModal = () => {
    setOpenExportExcel(true);
  };
  const handleCloseModal = () => {
    setOpenExportExcel(false);
  };

  const handleExportExcel = async (template: any) => {
    try {
      if (loadingExport) return;
      setLoadingExport(true);
      handleCloseModal();
      const queryString = generateSearchKeywords(query, true);
      const resp: any = await axios.get(
        `${API_URL}/${ID_APP}/${apiCode}${queryString}${
          !!queryString ? "&" : "?"
        }type_data=xlsx&id_template=${template._id}&access_token=${token}`,
        { responseType: "blob" }
      );
      // const resp: any = await axios.get(
      //   `${API_URL}/${ID_APP}/${apiCode}/excel${queryString}${
      //     !!queryString ? "&" : "?"
      //   }rpt=1&id_rpt=${template._id}&access_token=${token}`,
      //   { responseType: "blob" }
      // );
      if (resp && resp.status === 200) {
        saveAs(resp.data, fileName);
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message: error?.message || error?.error || "Lỗi khi xuất file excel",
      });
    } finally {
      setLoadingExport(false);
    }
  };

  const getFileExcels = async () => {
    try {
      const resp = await asyncGetList({
        withIdApp: false,
        apiCode: "exportexceltemplate",
        condition: { page: 1, limit: 10000, q: { code: apiCode } },
        token,
      });
      if (resp.status === 200) {
        const data = resp.data;
        if (isArray(data) && data.length > 0) {
          setFileExcels(data);
        }
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message: error?.message || error?.error || "Lỗi khi tải file excel",
      });
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Tên file",
      selector: (row) => row.title || row.ten_mau_in,
      width: "250px",
      wrap: true,
    },
    {
      name: "Xuất file",
      cell: (row) => (
        <IconButton
          onClick={() => handleExportExcel(row)}
          sx={{
            backgroundColor: "primary.main",
            color: "common.white",
            borderRadius: "10px",
            "&:hover": { backgroundColor: "primary.main" },
          }}
        >
          <TbFileExport size={16} />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (apiCode && !!openExportExcel) {
      getFileExcels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode, openExportExcel]);

  return (
    <>
      {openExportExcel && (
        <ModalBase
          width="600px"
          title="File excel"
          open={openExportExcel}
          handleClose={handleCloseModal}
          sx={{ zIndex: "9999999" }}
          actions={[
            <Button
              key={1}
              variant="contained"
              color="error"
              size="small"
              onClick={handleCloseModal}
              sx={{
                textTransform: "none",
                color: "common.white",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Đóng
            </Button>,
          ]}
        >
          <TableApp columns={columns} data={fileExcels} />
          <Stack sx={{ mt: 1 }}>
            <Typography sx={{ textAlign: "center", color: "secondary.main" }}>
              Vui lòng không thoát ra khi đang xuất file
            </Typography>
          </Stack>
        </ModalBase>
      )}
      {loadingExport && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            position: "fixed",
            zIndex: 100,
            bottom: 0,
            right: "5%",
            px: 2,
            py: 1,
            backgroundColor: "secondary.main",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Typography sx={{ color: "common.white" }}>
            Xuất {fileName}
          </Typography>
          <CircularProgress
            style={{ width: "20px", height: "20px" }}
            sx={{ color: "common.white" }}
          />
        </Stack>
      )}
      {!!renderDisplay ? (
        renderDisplay({ openModal: handleOpenModal })
      ) : (
        <IconButton onClick={handleOpenModal}>
          <FaRegFileExcel size={16} />
        </IconButton>
      )}
    </>
  );
}

export default ButtonExportToExcel;
