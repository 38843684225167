import React, { Dispatch, SetStateAction } from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import TextInput from "../../input/TextInput";
import SelectApiInput from "../../input/SelectApiInput";
import CheckboxInput from "../../input/CheckboxInput";
import generateSlug from "slug";
import NumberInput from "../../input/NumberInput";

interface IFormAdd {
  defaultValues?: any
  isEdit?: boolean
  open: boolean
  nhomMe: string
  onClose: () => void
  setLoad?: Dispatch<SetStateAction<any>>
}

const schema = yup.object({
  ten_nvt: yup.string().required("Vui lòng nhập tên nhóm"),
});

const apiCode = 'dmnvt'

function FormAdd({
  defaultValues,
  isEdit,
  open,
  nhomMe,
  onClose,
  setLoad,
}: IFormAdd) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    ten_nvt: "",
    la_nhom_hang_hoa: true,
    la_nhom_me: false,
    slug: "",
    stt: 0,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        la_nhom_me: !!defaultValues.exfields?.la_nhom_me,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { ten_nvt, la_nhom_me, slug, ...fields } = values;
    return {
      ...fields,
      ten_nvt,
      slug: slug || generateSlug(ten_nvt, "-"),
      nh_me: nhomMe,
      exfields: { ...(defaultValues?.exfields || {}), la_nhom_me },
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="600px"
      formTitle="nhóm con"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <NumberInput
          label="STT"
          labelWidth="25%"
          placeholder="Nhập số thứ tự nhóm"
          name="stt"
          register={register}
        />
        <TextInput
          required
          label="Tên nhóm hàng"
          labelWidth="25%"
          placeholder="Nhập tên nhóm hàng"
          name="ten_nvt"
          register={register}
          errorMessage={errors?.ten_nvt?.message as string}
        />
        <TextInput
          label="Slug"
          labelWidth="25%"
          placeholder="Nhập hoặc tạo tự động"
          name="slug"
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormAdd;
