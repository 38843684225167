import React, { useEffect, useState } from "react";
import { IExpand } from "../../../../types/expand.type";
import WrapperExpand from "../WrapperExpand";
import { Box, Button, Stack, Typography } from "@mui/material";
import { asyncDelete, asyncGetList } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import TableApp from "../../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";
import { LuPlusCircle } from "react-icons/lu";
import FormAdd from "./FormAdd";

const columns: TableColumn<any>[] = [
  {
    name: "STT",
    width: "80px",
    selector: (row) => row.stt,
  },
  {
    name: "Tên nhóm",
    width: "300px",
    wrap: true,
    selector: (row) => row.ten_nvt,
  },
];

function ExpandNhomHang({ data, onOpenEdit, setLoad }: IExpand) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [items, setItems] = useState<any[]>([]);
   const [defaultValues, setDefaultValues] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [toggleClear, setToggleClear] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<number>(0);
   const [paginationOption, setPaginationOption] = useState<{
      page: number;
      limit: number;
      totalRows: number;
    }>({ page: 1, limit: 20, totalRows: 0 });
  const [openFormAdd, setOpenFormAdd] = useState<boolean>(false);

  const handleCloseForm = () => {
    setOpenFormAdd(false);
  };

  const handleDelete = async () => {
    const resp = await asyncDelete({
      apiCode: "dmnvt",
      id: data?._id,
      token,
      idApp: app?._id,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi xóa dữ liệu",
      });
    } else {
      showAlert({ type: "success", message: `Đã xóa thành công` });
      setLoad?.((prev) => prev + 1);
    }
  };

  const getChildren = async () => {
    const condition = { page: paginationOption.page,
      limit: 20,
      sort: { stt: -1 },
      q: { nh_me: data?._id },}
    const resp = await asyncGetList({
      apiCode: "dmnvt",
      token,
      condition
    });
    const respCount = await asyncGetList({
      apiCode: "dmnvt",
      token,
      condition: {...condition, count: 1
      },
    });
    if (resp && resp?.status === 200) {
      setItems(resp?.data || []);
      setPaginationOption({...paginationOption, totalRows: respCount?.data?.rows_number || 0})
    }
  };

  useEffect(() => {
    getChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id]);

  return (
    <>
      {openFormAdd && <FormAdd open={openFormAdd} onClose={handleCloseForm} nhomMe={data?._id}  />}
      <WrapperExpand
        data={data}
        action={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ pt: 2 }}
          >
            <Button
              onClick={onOpenEdit}
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color: "common.white",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Chỉnh sửa
            </Button>
            <Button
              onClick={() => {
                showAlert({
                  title: "Xác nhận xóa",
                  type: "info",
                  message: "Bạn có chắc muốn xóa dòng này không ?",
                  onConfirm: handleDelete,
                });
              }}
              variant="contained"
              size="small"
              color="error"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color: "common.white",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Xóa
            </Button>
          </Stack>
        }
      >
        <Stack gap={1}>
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: 600 }}>Nhóm con</Typography>
            <Button
              onClick={() => setOpenFormAdd(true)}
              variant="outlined"
              size="small"
              startIcon={<LuPlusCircle size={14} />}
              sx={{
                textTransform: "none",
                boxShadow: "none",
                "&:hover": { boxShadow: "none" },
              }}
            >
              Thêm
            </Button>
          </Stack>
           <Stack sx={{ border: "1px solid", borderColor: "divider" }}>

            <TableApp columns={columns} data={items}  />
           </Stack>
        </Stack>
      </WrapperExpand>
    </>
  );
}

export default ExpandNhomHang;
