import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterNhomHang({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    tenNvt: "",
    nhomMe: [],
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.tenNvt) {
      condition.ten_nvt = {
        $regex: filter.tenNvt.split(" ").join(".*"),
        $options: "i",
      };
    }
    if ((filter.nhomMe || []).length > 0) {
      condition.nh_me = { $in: filter.nhomMe };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterText
          title="Nhóm hàng"
          placeholder="Tìm theo nhóm hàng"
          value={filter.tenNvt}
          onSearch={(value) => setFilter({ ...filter, tenNvt: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterNhomHang;
