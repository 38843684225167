import React, { useEffect, useState } from "react";
import FilterBox from "./FilterBox";
import { Stack } from "@mui/material";
import CheckboxInput from "../input/CheckboxInput";
import { cloneDeep, isArray } from "lodash";

type ItemType = {
  value: string;
  label: string;
  subs?: ItemType[];
};
interface IFilterList {
  title?: string;
  defaultValues?: string[];
  items?: ItemType[];
  onChange?: (values: string[]) => void;
}

function FilterList({ title, defaultValues, items, onChange }: IFilterList) {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<string[]>(defaultValues || []);

  const handleValueChange = (
    checked: boolean,
    value: string,
    subs?: { value: string; label: string }[],
    parent?: ItemType
  ) => {
    const isParent = isArray(subs) && subs?.length > 0;
    if (isParent) {
      const subValues = subs.map((item) => item.value);
      if (checked) {
        // Nếu check thì check tất cả con
        setValues((prev) => [...prev, value, ...subValues]);
      } else {
        // Nếu bỏ check thì bỏ check tất cả con
        setValues((prev) => {
          return prev.filter((item) => ![...subValues, value].includes(item));
        });
      }
    } else {
      if (checked) {
        if (parent) {
          // Nếu là phần tử cuối cùng được check thì check cha
          const siblingValues = parent.subs
            ?.map((item) => item.value)
            ?.filter((item) => item !== value);
          const clonedValues = cloneDeep(values);
          const isLast = siblingValues?.every((item) =>
            clonedValues.includes(item)
          );
          if (isLast) {
            setValues((prev) => [...prev, value, parent.value]);
          } else {
            setValues((prev) => [...prev, value]);
          }
        } else {
          setValues((prev) => [...prev, value]);
        }
      } else {
        if (parent) {
          // Nếu là phần tử đầu tiên bỏ check
          const siblingValues = parent.subs?.map((item) => item.value);
          const allCheck = siblingValues?.every((item) =>
            values.includes(item)
          );
          if (allCheck) {
            setValues((prev) => {
              return prev.filter(
                (item) => ![value, parent.value].includes(item)
              );
            });
          } else {
            setValues((prev) => prev.filter((item) => item !== value));
          }
        } else {
          setValues((prev) => prev.filter((item) => item !== value));
        }
      }
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
    onChange?.(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setValues(defaultValues || []);
  }, [defaultValues]);

  return (
    <FilterBox title={title} active={active}>
      <Stack sx={{ maxHeight: "50vh", overflow: "auto" }}>
        {isArray(items) &&
          items.map((item) => {
            const hasSub = isArray(item.subs) && item.subs?.length > 0;
            return (
              <Stack key={item.value}>
                <CheckboxInput
                  label={item.label}
                  checked={values.includes(item.value)}
                  onChange={(_, checked) =>
                    handleValueChange(
                      checked,
                      item.value,
                      hasSub ? item.subs : [],
                      undefined
                    )
                  }
                />
                {hasSub && (
                  <Stack sx={{ pl: 2 }}>
                    {item.subs?.map((sub) => {
                      return (
                        <CheckboxInput
                          key={sub.value}
                          label={sub.label}
                          checked={values.includes(sub.value)}
                          onChange={(_, checked) =>
                            handleValueChange(
                              checked,
                              sub.value,
                              undefined,
                              item
                            )
                          }
                        />
                      );
                    })}
                  </Stack>
                )}
              </Stack>
            );
          })}
      </Stack>
    </FilterBox>
  );
}

export default FilterList;
